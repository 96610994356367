import React from "react";
import "./index.css";

const YouTubeVideo = () => {
  return (
    <div className="video">
      <iframe
        width={1050}
        className="youtube"
        src="https://www.youtube.com/embed/OSG3sdSiOi8?rel=0&amp;&autoplay=1&mute=1&loop=1&vq=hd1080"
        frameborder="0"
        allowfullscreen
        title="YouTube video player"
      ></iframe>
    </div>
  );
};

export default YouTubeVideo;
